import { Box, Container, Typography } from "@material-ui/core"
import { format } from "date-fns"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { ArticleContentElement } from "../components/article/article-content/article-content-element"
import Layout from "../components/layout/layout-home"
import { PicturesWithLightBox } from "../components/utils/pictures-with-lightbox"

export default function ArticlePage(props) {
  const { data } = props
  const article = data.strapi.article

  return (
    <Layout>
      <Box
        pt="140px"
        pb="80px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box maxWidth="1000px" width="100%">
          <Container>
            <Box mt={2} mb={8}>
              <Typography variant="h1">{article.title}</Typography>
              <Typography
                variant="h3"
                style={{ fontWeight: "normal" }}
                color="primary"
              >
                {format(new Date(article.published_date), "dd/MM/yyyy")}
              </Typography>
            </Box>
            {article.content.map(element => (
              <ArticleContentElement element={element} />
            ))}
            {article.images && (
              <Box mt={6} mb={8}>
                <PicturesWithLightBox pictures={article.images} />
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ProjectPageQuery($id: ID!) {
    strapi {
      article(id: $id) {
        id
        title
        published_date
        thumbnail {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        images {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content {
          ... on STRAPI_ComponentArticleContentParagraph {
            id
            __typename
            text
          }
          ... on STRAPI_ComponentArticleContentVideo {
            id
            __typename
            url
          }
        }
      }
    }
  }
`
