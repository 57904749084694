import { Box, List, Typography } from "@material-ui/core"
import React from "react"
import ReactMarkdown from "react-markdown"
import { EmbedVideo } from "../../utils/embed-video"

export const ArticleContentElement = ({ element }) => {
  const components = {
    p: ({ children }) => <Typography variant="body1">{children}</Typography>,
    ul: ({ children }) => (
      <List style={{ textEmphasisColor: "#F26522" }}>{children}</List>
    ),
    a: ({ node, ...props }) => (
      <a
        style={{
          color: "#F26522",
          textDecoration: "none",
        }}
        {...props}
      />
    ),
  }

  return (
    <Box mb={4}>
      {element.__typename === "STRAPI_ComponentArticleContentParagraph" ? (
        <ReactMarkdown linkTarget="_blank" components={components}>
          {element.text}
        </ReactMarkdown>
      ) : element.__typename === "STRAPI_ComponentArticleContentVideo" ? (
        <div style={{ marginTop: "64px" }}>
          <EmbedVideo src={element.url} />
        </div>
      ) : (
        <div>{console.error("Unknown content element") && ""}</div>
      )}
    </Box>
  )
}
